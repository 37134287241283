document.addEventListener('DOMContentLoaded', init);

function init() {
    
    function onlyLetterInput(intext) {
      let jin = document.querySelectorAll(intext);
      for (let i = 0; i < jin.length; i++) {
        jin[i].addEventListener('input', function(e){
         this.value = this.value.replace(/\d/g, "");
         this.value = this.value.replace(/[.*_~`+;₴$₽'":%#@!*?^$-=<>№{}()|[\]\\]/g, "");
        });
      };
    };
    function onlyNubmerInput(intext) {
        let jin = document.querySelectorAll(intext);
        for (let i = 0; i < jin.length; i++) {
          jin[i].addEventListener('input', function(e){
           this.value = this.value.replace(/[^0-9]/g, "");
          });
        };
  };
  function findParent(el, cl) {
    let elem = el;
    if (elem.hasAttribute('data-handler')) {
      return false;
    }
    while (!elem.classList.contains(cl)) {
        if (elem.tagName.toLowerCase() === 'html') return false;
      elem = elem.parentNode;
      if (elem === null) {
        return elem
      }
      }
      return elem;
  };

    // detection devices
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // if it is not mobile
           
    } else {
         // if it is mobile
       
    };
    // detection devices
  
    jQuery.extend(jQuery.validator.messages, {
      required: "Поле является обязательным",
      remote: "Поле является обязательным",
      email: "Введите корректный электронный адрес",
    });
    $.validator.addMethod("EMAIL", function (value, element) {
      return this.optional(element) || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+\.[a-zA-Z.]{2,5}$/i.test(value);
    }, "Введите корректный электронный адрес");
    $.validator.addMethod("PASS", function (value, element) {
      return this.optional(element) || /^[A-Za-z0-9\d=!\-@._*]*$/i.test(value);
    }, "Не допустимы к использованию символы см. информацию о допустимых символах ниже");
    $.validator.addMethod("PHONE", function (value, element) {
      return this.optional(element) || /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/.test(value);
    }, "Введите коректный номер телефона");
    function validatorForm(elem) {
      $(elem).validate({
        errorPlacement: function(error, element) {
            if (element.attr("name") == "check_box") {
                element.parent().append(error);
            } else {
                error.insertAfter(element);
            }
            return true;
        },
        ignore: ":disabled",
        rules: {
          psword: {
            required: true,
            minlength: 6,
          },
          name: {
            required: true,
          },
          last_name: {
            required: true,
          },
          message: {
            required: true,
          },
          last_name: {
            required: true,
          },
          check_box: {
            required: true,
          },
          psword: "PASS",
          psword_confirm: {
            required: true,
            minlength: 6,
            equalTo: "#person-pass",
          },
          email_n: "required EMAIL",
          phone_ru_req: "required PHONE",
        },
        messages: {
          psword: {
            minlength: 'Минимальная длина пароля 6 символов'
          },
          psword_confirm: {
            equalTo: "Пароли не совпадают",
            minlength: 'Минимальная длина пароля 6 символов'
          },
          check_box: {
            required: 'Пожалуйста дайте согласие на обработку персональных данныхы'
          }
        },
      });
    }; 
   
    onlyLetterInput('.only_letter');
    onlyNubmerInput('.only_number');
    validatorForm("#reg_consult");
    validatorForm("#send_review");
    validatorForm("#send_ask");
    


  // header menu
  $('.settings').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('active');
    $('.set-menu-container').toggleClass('active');
  });
  $('body').click(function (e) {
    if ($(e.target).parents('.main-menu-wrapper').length == 0 && $('.settings').hasClass('active')) {
      $('.settings').toggleClass('active');
      $('.set-menu-container').toggleClass('active');
    }
  })
  // header menu

  // sibe-bar
  $('.btn__side_bar').click(function () {
    if ($(this).parent().hasClass('hide_mode')) {
      $(this).parent().removeClass('hide_mode');
      // $(this).parent().find('.menu_item').removeClass('active');
      $('body').addClass('open__side-bar')
    } else {
      $(this).parent().addClass('hide_mode');
      // $(this).parent().find('.menu_item').addClass('active');
      $('body').removeClass('open__side-bar')
    };
  });
  $('.menu_item').click(function () {
    if ($(this).parents('.side_bar').hasClass('hide_mode')) {
      $(this).parents('.side_bar').removeClass('hide_mode');
      $('body').addClass('open__side-bar');
    }
  })
  $('body').click(function (e) {
    if ($(e.target).hasClass('open__side-bar')) {
      $('.side_bar').addClass('hide_mode');
      $(this).removeClass('open__side-bar');
    }
  })
  // $('body').click(function (e) {
  //   let evtarget = $(e.target);
    //   $('.menu_item.active').removeClass('open');
    // if (evtarget.parents('.menu_item').hasClass('active')) {
    //   $('.menu_item.active').removeClass('open');
    //   evtarget.parents('.menu_item').addClass('open');
    // } else {
    //   if ($('.menu_item.active').hasClass('open')) {
    //     $('.menu_item.active').removeClass('open');
    //   };
    // };
  // });
  // sibe-bar

  // select
  $('.chose_select.multi').select2({
    language: "ru",
    closeOnSelect: false,
    width: 'element',
    selectionTitleAttribute: false,
    tags: false,
    "language": {
      "noResults": function () {
        return "Нет результатов ...";
      },
      "searching": function () {
        return "Поиск";
      }
    },
  });
  $('.chose_select:not(.multi)').select2({
    language: "ru",
    closeOnSelect: false,
    dropdownCssClass: '_single',
    width: 'element',
    selectionTitleAttribute: false,
    tags: false,
    "language": {
      "noResults": function () {
        return "Нет результатов ...";
      },
      "searching": function () {
        return "Поиск";
      }
    },
  });

  $('.chose_select').on('select2:open', function (e) {
    $('.select2-results__options').mCustomScrollbar('destroy');
    setTimeout(function () {
      $('.select2-results__options').mCustomScrollbar({
        theme: "my-theme",
        scrollButtons:{ enable: true }
      });
    }, 0);
  });
  function addTableRow(arry, parentElem) {
    let row = `
    <div class="row__intem add">
        <div class="col_item"></div>
        <div class="col_item"></div>
        <div class="col_item big_col"></div>
        <div class="col_item"></div>
        <div class="col_item big_col"></div>
    </div>`;
    
    if (arry.length >= $(parentElem).find('.row__intem.add').length) {
      let def = arry.length - $(parentElem).find('.row__intem.add').length;
      for (let i = 0; i < def; i++) {
        $(parentElem).append(row);
      }
    } else {
      $(parentElem).find('.row__intem.add').each(function () {
        let r = 0;
      $(this).find('.col_item').each(function () {
          console.log($(this).children().length)
        if ($(this).children().length != 0) {
          r = 1;
          return false;
        } else {
          r--;
        }
      });
        r <= 0 ? $(this).remove() : false;
      });
    };
  };
  
  function multiSelectEngine(elem, index) {
    let multiTabSelArr = [];
    let currentRow = $('.table_wrapper').find('.row__intem.add');
   
    currentRow.each(function (i, item) {
      let w = item.querySelectorAll('.col_item')[index];
      if (w.children.length != 0) {
        let d = w.querySelectorAll('.text');
        let f = [];
        d.forEach(item => f.push(item.innerText));
        multiTabSelArr.push(f);
      };
    });
    elem != 0 ? multiTabSelArr.push(elem) : false; ;
    addTableRow(multiTabSelArr, '.table_wrapper');
    renderMultiSelectInTable(multiTabSelArr, '.table_wrapper', index)
  }
  function renderMultiSelectInTable(arry, parentElem, index) {
    let currentRow = $(parentElem).find('.row__intem.add');
    // delet each item in a index colum
    currentRow.each(function (i, item) {
      item.querySelectorAll('.col_item')[index].innerHTML = '';
    })
    // delet each item in a index column
    // add new data in a column
    arry.forEach((item, i) => {
      currentRow[i].querySelectorAll('.col_item')[index].innerHTML = `<div class="select_item" data-col="${index}">
        <span class="text">${item[0]}</span>
        <span class="text">${item[1]}</span>
        <i class="close_btn-tab mul_s icon-close_item"></i>
        </div>`;
    });
    // add new data in a column
    
  }
  function renderSelectInTable(arry, parentElem, index) {
    let currentRow = $(parentElem).find('.row__intem.add');
    // delet each item in a index colum
    currentRow.each(function (i, item) {
      item.querySelectorAll('.col_item')[index].innerHTML = '';
    })
    // delet each item in a index column
    // add new data in a column
    arry.forEach((item, i) => {
      currentRow[i].querySelectorAll('.col_item')[index].innerHTML = `<div class="select_item" data-name="${i}" data-col="${index}">
        <span class="text">${item}</span>
        <i class="close_btn-tab icon-close_item"></i>
        </div>`;
    });
    // add new data in a column
  };
  function renderItem(selector, elem) {
    elem.forEach((item, i) => {
      selector.append(`<div class="select_item" data-name="${i}">
        <span class="text">${item}</span>
        <i class="close_btn icon-close_item"></i>
        </div>`);
      })
  }
  $('.table_sel_mult .chose_select.multi').on('change', function (e) {
    let selectedCurent = $(e.currentTarget).val()
    let renderChange = $(this).next('.select2').find('.select2-selection__rendered');
    if (selectedCurent == 0) {
      renderChange.html('');
      $(this).parents('.inline_wrapper').find('.add_person-btn').removeClass('active');
    } else {
      renderChange.html(`<li class="select2-selection__choice">${selectedCurent.join(' | ')}</li>`);
       $(this).parents('.inline_wrapper').find('.add_person-btn').addClass('active');
    }
  });
  $('.mult_sel .chose_select.add_tag').on('change', function (e) {
    let selectedCurent = $(e.currentTarget).val()
    let renderChange = $(this).next('.select2').find('.select2-selection__rendered');
    if (selectedCurent == 0) {
      renderChange.html('');
      $(this).parents('.row__intem').find('.select_item').remove();
    } else {
      renderChange.html(`<li class="select2-selection__choice">Выбрано ${selectedCurent.length} поз.</li>`);
      $(this).parents('.row__intem').find('.select_item').remove();
      renderItem($(this).parents('.row__intem'), selectedCurent);
    }
  });
  $('.mult_sel .chose_select').on('change', function (e) {
    let selectedCurent = $(e.currentTarget).val()
    let renderChange = $(this).next('.select2').find('.select2-selection__rendered');
    if (selectedCurent == 0) {
      renderChange.html('');
    } else {
      renderChange.html(`<li class="select2-selection__choice">Выбрано ${selectedCurent.length} поз.</li>`);
    }
  });
  
  $('.table_sel_one .chose_select').on('change', function (e) {
    let selectedCurent = $(e.currentTarget).val()
    let renderChange = $(this).next('.select2').find('.select2-selection__rendered');
    if (selectedCurent == 0) {
      renderChange.html('');
       $(this).parent().find('.add_person-btn').removeClass('active');
    } else {
      renderChange.html(`<li class="select2-selection__choice">Выбрано ${selectedCurent.length}</li>`);
      $(this).parent().find('.add_person-btn').addClass('active');
    }
    
  });
  
  $('body').on('click', '.close_btn-tab', function (e) {
    let nameItem = +$(this).parent().attr('data-name');
    let colIndex = $(this).parent().attr('data-col');
    let currSel = $(this).parents('.table_wrapper').find('div[data-pos=' + colIndex + ']').find('.chose_select');
    let selArry = currSel.val();
    selArry.splice(nameItem, 1);
    currSel.val(selArry).trigger("change");
    addTableRow(selArry, '.table_wrapper');
    renderSelectInTable(selArry, '.table_wrapper', colIndex);
  });
  $('body').on('click', '.close_btn', function (e) {
    let s = +$(this).parent().attr('data-name');
    let paterntElem = $(this).parents('.row__intem');
    let selArry = paterntElem.find('.chose_select').val();
    selArry.splice(s, 1);
    paterntElem.find('.select_item').remove();
    renderItem(paterntElem, selArry);
    paterntElem.find('.chose_select').val(selArry).trigger("change")
  });
  $('.chose_select:not(.multi)').one('select2:open', function(e) {
    $('input.select2-search__field').prop('placeholder', 'Поиск');
  });
  $('.col_item').on('click', '.add_person-btn', function (e) {
    let str = '',
      array = [],
      indexCol = +$(this).parents('.col_item').attr('data-pos');
    if ($(this).parent().hasClass('input_wrapper')) {
      array = $(this).parent().find('.chose_select').val();
      addTableRow(array, '.table_wrapper');
      renderSelectInTable(array, '.table_wrapper', indexCol);
      
    } else {
      $(this).parent().find('.chose_select').each((i, item) => {
        // str = str + item.value.join(' ');
        if (typeof $(item).val() == 'object') {
          str = str + $(item).val().join(' | ');
        } else {
          str = str + item.value + ',';
        }
      })
      array = str.split(',');
      multiSelectEngine(array, indexCol);
    }
  });
  $('.table_wrapper').on('click', '.close_btn-tab.mul_s', function (e) {
    let numCol = $(this).parent().attr('data-col');
    $(this).parent().remove();
    multiSelectEngine([], numCol);
  });
  // select
  
  let rusLocalSetting = {
      format: 'DD.MM.YYYY HH:MM',
      applyLabel: "Выбрать",
      cancelLabel: "Отменить",
      daysOfWeek: [
        "Вос.",
        "Пон.",
        "Вт.",
        "Ср.",
        "Чет.",
        "Пят.",
        "Суб."
      ],
      monthNames: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      monthShort: [
        "Янв",
        "Фев",
        "Мар",
        "Апр",
        "Май",
        "Июн",
        "Июл",
        "Авг",
        "Сен",
        "Окт",
        "Ноя",
        "Дек"
      ],
      firstDay: 1
  }
  // datapicker init
  function sindelDatePicker(elem, loction, onsingrlMod = true, time = true) {
     $(elem).daterangepicker({
       autoUpdateInput: false,
       singleDatePicker: onsingrlMod,
       timePicker24Hour: time,
       autoApply: true,
       timePicker: time,
       startDate: moment().startOf('hour'),
       maxYear: 2035,
       opens: "center",
       drops: "up",
       locale: loction
     });
  };
  function fixMBug(elem) {
     $(elem).on('showCalendar.daterangepicker', function(ev, picker) {
       $('.daterangepicker').find('.right').find('.table-condensed').find('thead').find('.month').prev().addClass('prev available').html('<span></span>');
     });
  }
  // call picker 
  sindelDatePicker('.time-date-pick', rusLocalSetting, false, true);
  sindelDatePicker('.date-pick', rusLocalSetting, false, false);
  // call event picker
  $('.time-date-pick').on('hide.daterangepicker', function(ev, picker) {
      $(this).val( picker.startDate.format('DD.M HH:MM') + ' - ' + picker.endDate.format('DD.M HH:MM'));
  });
  $('.date-pick').on('apply.daterangepicker', function(ev, picker) {
      $(this).val( picker.startDate.format('DD.MM') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
  });
  fixMBug('.time-date-pick');
  fixMBug('.date-pick');
  // datapicker end

  // PopUp
  function withScrollBar() {
    let popWrap = document.querySelector('main');
    let wScroll = window.innerWidth - popWrap.offsetWidth;
    return wScroll;
  };
  function openPopUp(elem) {
    let wScrollBar = withScrollBar(),
      scrollTop = window.pageYOffset;
    $(elem).addClass('active');
    $('body').addClass('modal');
    $('body').css('padding-right', wScrollBar);
  };
  function closePopUp(elem) {
    let scrollTop = window.pageYOffset;
    $(elem).removeClass('active');
    $('body').removeClass('modal');
    $('body').css('padding-right', 'unset');
    window.scroll(0, scrollTop);
  };
  function checkAction(elem, pop) {
    let text = elem.innerHTML;
    let innerPopUp = document.querySelector(pop);
    innerPopUp.querySelector('.type_action').innerHTML = text;
    openPopUp(pop);
  }
  // PopUp
  // open popUP
  $('.btn__tree').click(function (e) {
    e.preventDefault();
    openPopUp('#tree');
  })
  $('.page_control__btn').click(function (e) {
    if (e.target.classList.contains('btn_finish_event') || e.target.classList.contains('btn_cancel_event') || e.target.classList.contains('btn_archive_event')) {
        checkAction(e.target, '#allow_action');
    }
  })
  // open popUP

  // close popUP
  $('body').click(function (e) {
    if (e.target.classList.contains('pop-up')) {
      closePopUp('#' + e.target.id);
    } else return;
  })
  $('.close_btn').click(function (e) {
    e.preventDefault();
    closePopUp('#' + $(this).parents('.pop-up').attr('id'));
  });
  $('.btn_cancel_action').click(function (e) {
    e.preventDefault();
    closePopUp('#' + $(this).parents('.pop-up').attr('id'));
  });
  $('.btn_allow_action').click(function (e) {
    e.preventDefault();
    $('#see-event').submit();  // or do some other action
  });
  // close popUP

  //PopUp

  // tree JS
  // data for tree 
  let data = [
    {
        name: '2022', id: 1,
        children: [
          {
            name: 'Внешние',
            id: 2,
            children: [
              {
                name: 'Визиты в РФ', id: 3
              },
              {
                name: 'Визиты за рубеж',
                id: 4,
                children: [
                  {
                    name: 'Раб.визит',
                    id: 5,
                    children: [
                      {
                        name: 'Аккредитация',
                        id: 6,
                         
                      },
                      {
                        name: 'Письма',
                        id: 7,
                        children: [
                          {name: 'Письма с приглашениями', id: 8, }
                        ]
                      },
                    ]
                     
                  },
                ]
              }
            ]
          },
          {
            name: 'Внутренние',
            id: 9,
            children: [
               {name: 'Мероприятия в Москве и МО', id: 10, },
              {
                name: 'Поездки по стране',
                id: 11,
                children: [
                  {
                    name: 'Раб.поездка',
                    id: 12,
                    children: [
                      {
                        name: 'Обеспечение',
                        id: 13,
                        children: [
                          {
                            name: 'Письма',
                            id: 14,
                            children: [
                              {
                                name: 'Письма с приглашениями',
                                id: 15,
                              
                              },
                            
                            ]
                          
                          },
                        
                        ]

                      },

                    ]

                  },
                  
                ]
              },
            ]

          }
        ]
    },
    {
        name: '2021', id: 16,
         children: [
          {
            name: 'Внешние',
            id: 2,
            children: [
              {
                name: 'Визиты в РФ', id: 17
              },
              {
                name: 'Визиты за рубеж',
                id: 18,
                children: [
                  {
                    name: 'Раб.визит',
                    id: 19,
                    children: [
                      {
                        name: 'Аккредитация',
                        id: 20,
                         
                      },
                      {
                        name: 'Письма',
                        id: 21,
                        children: [
                          {name: 'Письма с приглашениями', id: 22, }
                        ]
                      },
                    ]
                     
                  },
                ]
              }
            ]
          },
          {
            name: 'Внутренние',
            id: 23,
            children: [
               {name: 'Мероприятия в Москве и МО', id: 24, },
              {
                name: 'Поездки по стране',
                id: 25,
                children: [
                  {
                    name: 'Раб.поездка',
                    id: 26,
                    children: [
                      {
                        name: 'Обеспечение',
                        id: 27,
                        children: [
                          {
                            name: 'Письма',
                            id: 28,
                            children: [
                              {
                                name: 'Письма с приглашениями',
                                id: 29,
                              
                              },
                            
                            ]
                          
                          },
                        
                        ]

                      },

                    ]

                  },
                  
                ]
              },
            ]

          }
        ]
    }
  ];
  // init tree
  $('#tree1').tree({
    data: data,
    autoOpen: true,
    dragAndDrop: true
  });
  // tree JS
  let cheackTee; 
  $('.tree_style').mCustomScrollbar({
       theme: "my-theme",
       scrollButtons:{ enable: true }
  });
  $('#tree1').on(
    'tree.select',
    function(event) {
        if (event.node) {
            cheackTee = true;
        }
        else {
           cheackTee = false;
        }
    }
  ); 
  // tree JS
  // validation create_event form
  $('.btn_save').click(function (e) {
    e.preventDefault();
    if ($('#req_text').val() != 0 && cheackTee == true) {
      $('#create-event').submit();
    } else {
      alert('Заполните/отметьте, пожалуйста, обязательные поля обозначенные звездочкой');
    }
  });
  // validation create_event form
  // validation authorization form
     $('#sing_in_btn').click(function (e) {
       e.preventDefault();
       if ($(this).parents('.form').find('#login').val() != 0 && $(this).parents('.form').find('#pass').val()) {
          $(this).parents('.form').submit();
       } else {
         alert('Поля не заполенны');
       }
     });
  // validation authorization form

  // smart filter
  let filter = $('.filter-wrapper:not(.no-main)');
  filter.hide();
  $('.switch-filter').click(function () {
    if ($(this).hasClass('open')) {
      filter.hide("slow");
      $(this).removeClass('open');
      $(this).find('.state.hide_in').removeClass('show');
      $(this).find('.state.show_in').addClass('show');
    } else {
      filter.show("slow");
      $(this).addClass('open');
      $(this).find('.state.hide_in').addClass('show');
      $(this).find('.state.show_in').removeClass('show');
    }
  });
  // smart filter

  // add selcet all option to select2
  $('.state-select .sel-all input').click(function () {
    if ($(this).prop('checked')) {
      $(this).parents('.sel_pop_style').find('.sel-item input').each(function () {
        $(this).prop('checked', true);
      })
    } else {
       $(this).parents('.sel_pop_style').find('.sel-item input').each(function () {
        $(this).prop('checked', false);
      })
    }
  });
  $('.state-select .sel-item input').change(function () {
    let y = 0,
      quantElem = $(this).parents('.sel_pop_style').find('.sel-item input'),
      allSel = $(this).parents('.sel_pop_style').find('.sel-all input');
    quantElem.each(function () {
      if ($(this).prop('checked')) {
        y++;
      }
    });
    if (quantElem.length != y) {
      allSel.prop('checked', false);
    } else if (quantElem.length == y) allSel.prop('checked', true);
  });
  // close select
  $('body').click(function (event) {
    if (findParent(event.target, 'input_wrapper')) {
    } else {
      $('input[type="checkbox"].select_engine').prop('checked', false);
      $('input[type="checkbox"].select_engine').removeClass('open');
    }
  });
  // add selcet all option to select2
}
